import React from "react";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-black text-center max-w-sm`;


const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-red-700 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-red-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-red-700 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-red-500 fill-current w-24`;

export default () => {

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Quiénes somos</HeadingTitle>
          <HeadingDescription>
            En un mundo adicto al oleaje informativo y expuesto a la pandemia de la desinformación, nace Politipedia: la plataforma de conocimiento político más grande de México.
            <br></br>
            <br></br>¿Nuestro objetivo? <strong>Jerarquizar y organizar a los hombres, las mujeres y organismos del poder.</strong>
            <br></br>
            <br></br>
            Convencidos de que la tecnología llegó para democratizar la información, surge Politipedia. 
            <br></br>
            <br></br>
            Somos el resultado de una vanguardista simbiosis entre la información y la tecnología. 
            <br></br>
            <br></br>
            Cimentado en las tecnologías más innovadoras, Politipedia será el catalizador político de México a través de las herramientas y soluciones más eficientes en el manejo y la organización de los datos.
            <br></br>
            <br></br>
            Te presentaremos a <strong>la nomenclatura política como jamás se había conocido.</strong>
          </HeadingDescription>
        </HeadingInfoContainer>

      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
