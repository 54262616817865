import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logofooter from "../../images/logofooter.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";


const Container = tw(ContainerBase)`bg-black text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-red-700 transition duration-300 mx-4`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-white`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logofooter} />
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/PolitipediaMX">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/PolitipediaMX">
              <TwitterIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; 2020, Politipedia. Todos los derechos reservados.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
